<template>
  <div>
    <!-- basic modal -->
    <b-modal id="modal-4" :title="'Periode Penyusunan Tahun Anggaran ' + tahun_anggaran.th" ok-disabled
      no-close-on-backdrop size="lg" no-close-on-esc hide-footer>
      <b-card-text>
        <validation-observer ref="simpleRules">
          <b-form>
            <b-form-group label="Tahun Penyusunan Anggaran" label-for="basicInput">
              <b-form-input id="basicInput" placeholder="" v-model="tahun_anggaran.th" disabled />
            </b-form-group>

            <b-form-group label="Title" label-for="title">
              <validation-provider #default="{ errors }" rules="required" name="judul">
                <b-form-input v-model="input.judul" disabled placeholder="Judul Periode Penyusunan Anggaran" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-row>
              <b-col md="6">
                <b-form-group label="Tanggal Mulai" label-for="tglmulai">
                  <validation-provider rules="required" name="tglmulai">
                    <b-form-input v-model="input.tgl_mulai" disabled placeholder="Judul Periode Penyusunan Anggaran" />


                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Tanggal Selesai" label-for="tglselesai">
                  <validation-provider #default="{ errors }" rules="required" name="tglselesai">
                    <flat-pickr v-model="input.tgl_selesai" class="form-control" :config="{ dateFormat: 'd-m-Y', }"
                      :state="errors.length > 0 ? false : null" placeholder="Tanggal MUlai" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-form-group label="Status Akses Penyusunan Anggaran" label-for="tglselesai">
              <b-row>
                <b-col md="4">
                  <b-form-radio v-model="input.status" name="some-radios" value="All">
                    Semua Balai/Bidang
                  </b-form-radio>
                </b-col>
                <b-col md="3">
                  <b-form-radio v-model="input.status" name="some-radios" value="Terbatas">
                    Terbatas
                  </b-form-radio>
                </b-col>
              </b-row>
            </b-form-group>
            <hr>
            <b-button variant="primary" type="submit" @click.prevent="validationForm" size="sm">
              <feather-icon icon="SaveIcon" size='15' /> Simpan Pengajuan
            </b-button>
            <b-button variant="warning" type="submit" @click.prevent="close" size="sm" class="ml-1">
              <feather-icon icon="RefreshCcwIcon" size='15' /> Keluar
            </b-button>

          </b-form>
        </validation-observer>

      </b-card-text>
    </b-modal>

  </div>
</template>

<script>


import flatPickr from 'vue-flatpickr-component'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required,
} from '@validations'
import Base from '@/config/Mixins_base'
import { BModal, BButton, VBModal, BAlert, BFormDatepicker, BFormInput, BCol, BRow, BFormRadio, BFormGroup } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BButton, flatPickr,
    BModal, ValidationProvider, ValidationObserver, BFormRadio,
    BAlert, BFormDatepicker, BFormGroup, BFormInput, BCol, BRow
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      required,
    }
  },
  mixins: [Base],
  mounted() {


  },
  emits: ["updatePeriode"],
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.$emit("updatePeriode", this.input)
          this.input = {}
          this.close()
        }
      })

    },
    close() {
      this.$root.$emit('bv::hide::modal', 'modal-4')
    }
  },
  props: {
    tahun_anggaran: {}, input: {}
  },
  computed: {
    // setTahun: {
    //   get: function () {
    //	 return this.tahun;
    //   },
    //   set: function (newValue) {
    //	 return this.tahun = newValue;
    //   }
    // }, 

  },
}
</script>


<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>