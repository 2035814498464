<template>
    <b-row>
        <b-col md="5" sm="4" class="my-1">
            <b-form-group label="" label-align-sm="right" label-size="sm" label-for="filterInput" class="mb-0">
                <b-input-group size="sm">
                    <b-form-input id="filterInput" v-model="filter" type="search" placeholder="Type to Search" />
                </b-input-group>
            </b-form-group>
        </b-col>

        <b-col md="4" class="my-1">

        </b-col>
        <b-col md="3" class="my-1">
            <b-button variant="info" block size="sm" v-b-modal.modal-2>
                <feather-icon size='15' icon="PlusCircleIcon"></feather-icon> Tambah Periode Pengajuan
            </b-button>
        </b-col>

        <b-col cols="12">
            <b-table striped hover responsive :per-page="perPage" :current-page="currentPage" :items="items"
                :fields="fields" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection"
                :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered">
                <template #cell(wbs)="data">
                    <b-row>
                        <b-col md="4">
                            <h6 class="text-primary"><strong>{{ data.item.judul }}</strong></h6>

                            <h5 class="text-primary"> <small class="text-primary">Tahun Anggaran <b>{{
                                data.item.th_anggaran }}</b></small>
                            </h5>

                        </b-col>
                        <b-col md="7">
                            <h6 class="text-primary"> <small> Periode Penganggaran </small> <br><small
                                    style="text-align: justify;">
                                    <strong> {{ data.item.tgl_mulai }} </strong><i>sampai dengan</i> <strong> {{
                                        data.item.tgl_selesai }}</strong></small></h6>
                            <b-row>
                                <b-col md="9"><small>Status Pengajuan <strong>{{ data.item.status }}</strong></small><br>
                                    <strong class="text-warning">{{ data.item.statusPengajuan }}</strong>
                                </b-col>
                                <b-col md="3" v-if="data.item.status != 'All'">
                                    <b-badge block variant="info" size="sm" class="btn-block" v-b-modal.modal-1
                                        @click="DetailData(data.item)">
                                        <span><feather-icon icon="EditIcon" size="15"></feather-icon> Tambah BPJ</span>
                                    </b-badge>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col md="4" v-for=" balai_bidang  in   data.item.balai_bidang " v-bind:key="balai_bidang">
                                    <b-badge variant="danger"> <feather-icon icon="TrashIcon" size='15'
                                            @click="DeleteBalaiBidang(balai_bidang.id)" /></b-badge> {{
                                                balai_bidang.nama }}
                                </b-col>
                            </b-row>


                        </b-col>
                        <b-col md="1">
                            <b-button variant="warning" size="sm" v-b-modal.modal-4 @click="DetailData(data.item)">
                                <feather-icon icon="Edit2Icon" size="15"></feather-icon>
                            </b-button>
                        </b-col>
                    </b-row>
                </template>
            </b-table>
        </b-col>

        <b-col cols="12">
            <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="right" size="sm"
                class="my-0" />
        </b-col>
    </b-row>
</template>

<script>
import {
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
} from 'bootstrap-vue'

export default {
    components: {
        BTable,
        BAvatar,
        BBadge,
        BRow,
        BCol,
        BFormGroup,
        BFormSelect,
        BPagination,
        BInputGroup,
        BFormInput,
        BInputGroupAppend,
        BButton,
    },
    props: {
        rs_data: {}
    },
    data() {
        return {
            perPage: 5,
            pageOptions: [3, 5, 10],
            totalRows: 1,
            currentPage: 1,
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
            fields: [{
                key: 'wbs',
                label: '',
                thStyle: {
                    display: 'none'
                }
            }],


        }
    },
    computed: {
        items: {
            get: function () {
                return this.rs_data;
            },
            set: function (newValue) {
                return this.rs_data = newValue;
            }
        },

        sortOptions() {
            // Create an options list from our fields
            return this.fields
                .filter(f => f.sortable)
                .map(f => ({
                    text: f.label,
                    value: f.key
                }))
        },
    },
    mounted() {
        // Set the initial number of items
        this.totalRows = this.items.length
    },
    emits: ['DetailData', 'DeleteBalaiBidang'],
    methods: {
        DetailData(val) {
            this.$emit('DetailData', val)
        },

        DeleteBalaiBidang(val) {
            if (confirm('Apakah anda yakin akan menghapus data ini....?')) {
                this.$emit('DeleteBalaiBidang', val)
            }
        },
        info(item, index, button) {
            this.infoModal.title = `Row index: ${index}`
            this.infoModal.content = JSON.stringify(item, null, 2)
            this.$root.$emit('bv::show::modal', this.infoModal.id, button)
        },
        resetInfoModal() {
            this.infoModal.title = ''
            this.infoModal.content = ''
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
    },
}
</script>
<style>
.btn-block {
    display: block;
    width: 100%;
}
</style>