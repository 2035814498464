<template>
    <div>
        <b-row>
            <b-col>
                <b-card>
                    <tabel_data :rs_data=rs_data @DetailData=DetailData @DeleteBalaiBidang=DeleteBalaiBidang></tabel_data>
                </b-card>
            </b-col>

        </b-row>
        <kelola-wilayah :wilayah="wilayah" :rs_detail=rs_detail @InsertData=InsertData></kelola-wilayah>
        <tambah-periode :tahun_anggaran=tahun_anggaran @InsertPeriode=InsertPeriode></tambah-periode>
        <editperiode_anggaran :tahun_anggaran=tahun_anggaran :input="rs_detail" @updatePeriode="updatePeriode">
        </editperiode_anggaran>
    </div>
</template> 
 
<script>
import axios from '@/config/Axios';
import Base from '@/config/Mixins_base';
import tabel_data from './component/tabel.vue';
import {
    BCard,
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,

    BButton,
} from 'bootstrap-vue'
import KelolaWilayah from './component/KelolaWilayah.vue';
import TambahPeriode from './component/TambahPeriode.vue';
import editperiode_anggaran from './component/EditPeriodeAnggaran.vue';

export default {
    components: {
        BCard, tabel_data,
        BTable,
        BAvatar,
        BBadge,
        BRow,
        BCol,
        BButton,
        KelolaWilayah,
        TambahPeriode, editperiode_anggaran
    },
    data() {
        return {
            rs_data: [],
            tahun_anggaran: {}, wilayah: [],
            rs_detail: {}
        }

    },
    mixins: [Base],
    computed: {},
    mounted() {
        this.get_wilayah();
        this.load_data();
        this.get_tahun_anggaran();
    },
    methods: {
        DetailData(val) {
            this.rs_detail = val
        },
        async get_wilayah() {
            const self = this;
            await axios({
                method: 'PUT',
                url: '/api/pu/setup/periode_anggaran/load_balai_bidang',
                data: {

                },
                headers: {
                    'Authorization': self.isAuthenticated
                }
            })
                .then(function (response) {
                    self.wilayah = response.data.result;

                }).catch(err => {
                    self.pesan = err.message;
                    self.notification('warning', "Error", err.message);
                });
        },
        async load_data() {
            const self = this;
            await axios({
                method: 'PUT',
                url: '/api/pu/setup/periode_anggaran/load_data',
                data: {

                },
                headers: {
                    'Authorization': self.isAuthenticated
                }
            })
                .then(function (response) {
                    self.rs_data = response.data.result;

                }).catch(err => {
                    self.pesan = err.message;
                    self.notification('warning', "Error", err.message);
                });
        },
        async get_tahun_anggaran() {
            const self = this;
            await axios({
                method: 'PUT',
                url: '/api/pu/setup/periode_anggaran/tahun_anggaran',
                data: {

                },
                headers: {
                    'Authorization': self.isAuthenticated
                }
            })
                .then(function (response) {
                    self.tahun_anggaran = response.data.result;

                }).catch(err => {
                    self.pesan = err.message;
                    self.notification('warning', "Error", err.message);
                });
        },
        async InsertPeriode(input) {
            const self = this;
            await axios({
                method: 'POST',
                url: '/api/pu/setup/periode_anggaran/insert',
                data: {
                    th_anggaran: input.th_anggaran,
                    tgl_mulai: input.tgl_mulai,
                    tgl_selesai: input.tgl_selesai,
                    judul: input.judul,
                    status: input.status,
                },
                headers: {
                    'Authorization': self.isAuthenticated
                }
            })
                .then(function (response) {
                    self.notification('warning', "", response.data.message);
                    self.load_data();

                }).catch(err => {
                    self.pesan = err.message;
                    self.notification('warning', "Error", err.message);
                });
        },
        async updatePeriode(input) {
            const self = this;
            await axios({
                method: 'POST',
                url: '/api/pu/setup/periode_anggaran/update',
                data: {
                    id_setup_periode_anggaran: input.id_setup_periode_anggaran,
                    tgl_selesai: input.tgl_selesai,
                    status: input.status,
                },
                headers: {
                    'Authorization': self.isAuthenticated
                }
            })
                .then(function (response) {
                    self.notification('warning', "", response.data.message);
                    self.load_data();

                }).catch(err => {
                    self.pesan = err.message;
                    self.notification('warning', "Error", err.message);
                });
        },

        async InsertData(id_bidang, id_setup_periode_anggaran) {
            const self = this;
            await axios({
                method: 'POST',
                url: '/api/pu/setup/periode_anggaran/insert_balai_bidang',
                data: {
                    id_balai_bidang: id_bidang,
                    id_setup_periode_anggaran: id_setup_periode_anggaran
                },
                headers: {
                    'Authorization': self.isAuthenticated
                }
            })
                .then(function (response) {
                    self.notification('warning', "", response.data.message);
                    self.load_data();

                }).catch(err => {
                    self.pesan = err.message;
                    self.notification('warning', "Error", err.message);
                });
        },
        async DeleteBalaiBidang(id) {
            const self = this;
            await axios({
                method: 'POST',
                url: '/api/pu/setup/periode_anggaran/delete_balai_bidang',
                data: {

                    id: id
                },
                headers: {
                    'Authorization': self.isAuthenticated
                }
            })
                .then(function (response) {
                    self.notification('warning', "", response.data.message);
                    self.load_data();

                }).catch(err => {
                    self.pesan = err.message;
                    self.notification('warning', "Error", err.message);
                });
        },
    },
}
</script>